/** @jsx jsx */
// noinspection ES6UnusedImports
import {Box, jsx, Themed} from 'theme-ui'
import React from 'react'
import {Layout} from '../layout/layout'
import {StaticImage} from 'gatsby-plugin-image'
import EngineerIcon from '../graphics/engineer.svg'
import {IconSquare} from '../layout/icon-square'
import {TitledList} from '../layout/titled-list'
import {SectionGray, SectionImage, SectionWhite} from '../layout/section'

const PartnersAndProjects = () => (
  <Layout>
    <SectionImage image={(
      <StaticImage
        sx={{
          width: '100%',
          height: '100%',
        }}
        loading="eager"
        layout="fullWidth"
        src="../images/what-we-do.jpg"
        alt=""/>
    )}>
      <Themed.h1 sx={{
        color: 'white',
        marginBottom: ['2rem', '1rem'],
        maxWidth: '46rem',
        fontWeight: 'regular',
      }}>
        An optimized approach to digital transformations.
      </Themed.h1>

      <Box sx={{
        maxWidth: '42rem',
        fontWeight: 'regular',
      }}>
        Our process begins and ends with you, leveraging our full range of services to design and deploy a business
        transformation that’s unique to your organization.
      </Box>
    </SectionImage>

    <SectionWhite>
      <Themed.h1 sx={{
        textAlign: ['center', 'initial'],
        color: 'atxOrange',
        fontWeight: 'bold',
        mb: '1rem',
      }}>
        Technology<br/>
        integration.<br/>
        Business<br/>
        transformation.
      </Themed.h1>
      <Box sx={{fontWeight: 'medium', textAlign: ['center', 'initial']}}>
        As a management consultancy focused on Software Factories within DoD, our services span from mapping and
        implementing business processes to designing and coding essential technology.
      </Box>
    </SectionWhite>

    <SectionGray>
      <Themed.h2 sx={{fontWeight: 'bold', mb: '2rem', textAlign: 'center'}}>
        OUR 4 LEVELS OF SERVICE
      </Themed.h2>

      <Box sx={{
        display: 'grid',
        gridTemplateColumns: ['1fr', '1fr 1fr'],
        gap: '2rem',
      }}>
        <ServiceLevel
          title="Organizational Design & Strategy"
          items={[
            'Chartering & Roadmapping',
            'Business Process Optimization',
            'Data-driven Work Design',
            'Business Plan Implementation',
          ]}
          icon={<EngineerIcon/>}/>

        <ServiceLevel
          title="Technical Integration"
          items={[
            'Scoping',
            'Custom Application Design and Development',
            'Application Integration',
            'Continuous Feature Development',
          ]}
          icon={<EngineerIcon/>}/>

        <ServiceLevel
          title="Business Transformation"
          items={[
            'Process Design',
            'Process Measurement and Analysis',
            'Process Improvement and Control',
            'Process Optimization During Tech Integrations',
          ]}
          icon={<EngineerIcon/>}/>

        <ServiceLevel
          title="Stakeholder Engagement"
          items={[
            'Relationship Building',
            'Partnership Program Development',
            'Defense Community Insights',
            'Austin Community Support',
          ]}
          icon={<EngineerIcon/>}/>
      </Box>
    </SectionGray>
  </Layout>
)

type ServiceLevelProps = {
  title: string
  items: string[]
  icon: React.ReactElement
}

const ServiceLevel = ({title, items, icon}: ServiceLevelProps) => (
  <Box sx={{
    background: 'linear-gradient(to bottom, white 0%, white 100%) no-repeat',
    backgroundSize: '100% calc(100% - 35px)',
    backgroundPosition: 'bottom',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 1rem 3rem 1rem',
    gap: '1rem',
  }}>
    <IconSquare icon={icon}/>
    <TitledList title={title} items={items}/>
  </Box>
)

export default PartnersAndProjects
